<template>
  <div class="mt-7 max-w-sm mx-auto text-center">
    <p class="mt-7 text-7xl font-bold">{{ error.statusCode }}</p>
    <p class="mt-7 text-6xl">Oooops.</p>
    <p class="mt-7">{{ error.message }}</p>
    <button class="my-7" @click="handleClearError">Go Home...</button>
  </div>
</template>

<script setup lang="ts">
defineProps({
  error: { type: Object, required: true },
});

const handleClearError = () => clearError({ redirect: "/" });
</script>

<style scoped></style>
