import { default as _91type_93_45_91id_93aUEUyOBvXnMeta } from "/app/pages/[type]-[id].vue?macro=true";
import { default as forgot_45password1UbT4qDpFeMeta } from "/app/pages/forgot-password.vue?macro=true";
import { default as health_45checkePC2yKKeYyMeta } from "/app/pages/health-check.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as index6qjVTb140iMeta } from "/app/pages/landingpage/index.vue?macro=true";
import { default as loginhHM0vSTW5jMeta } from "/app/pages/login.vue?macro=true";
import { default as mark_45readlU1BJPklcOMeta } from "/app/pages/mark-read.vue?macro=true";
import { default as organizezlsEXyj0g1Meta } from "/app/pages/organize.vue?macro=true";
import { default as profileupBlgdy3vLMeta } from "/app/pages/profile.vue?macro=true";
import { default as reset_45password52TOS9kgCUMeta } from "/app/pages/reset-password.vue?macro=true";
import { default as retainoYnpBDz9wtMeta } from "/app/pages/retain.vue?macro=true";
import { default as settingsLwEYOlkQxrMeta } from "/app/pages/settings.vue?macro=true";
import { default as trashClIJQCcKHcMeta } from "/app/pages/trash.vue?macro=true";
export default [
  {
    name: "type-id",
    path: "/:type()-:id()",
    meta: _91type_93_45_91id_93aUEUyOBvXnMeta || {},
    component: () => import("/app/pages/[type]-[id].vue").then(m => m.default || m)
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    component: () => import("/app/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "health-check",
    path: "/health-check",
    component: () => import("/app/pages/health-check.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "landingpage",
    path: "/landingpage",
    component: () => import("/app/pages/landingpage/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "mark-read",
    path: "/mark-read",
    component: () => import("/app/pages/mark-read.vue").then(m => m.default || m)
  },
  {
    name: "organize",
    path: "/organize",
    meta: organizezlsEXyj0g1Meta || {},
    component: () => import("/app/pages/organize.vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "/profile",
    meta: profileupBlgdy3vLMeta || {},
    component: () => import("/app/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: "reset-password",
    path: "/reset-password",
    component: () => import("/app/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "retain",
    path: "/retain",
    meta: retainoYnpBDz9wtMeta || {},
    component: () => import("/app/pages/retain.vue").then(m => m.default || m)
  },
  {
    name: "settings",
    path: "/settings",
    meta: settingsLwEYOlkQxrMeta || {},
    component: () => import("/app/pages/settings.vue").then(m => m.default || m)
  },
  {
    name: "trash",
    path: "/trash",
    meta: trashClIJQCcKHcMeta || {},
    component: () => import("/app/pages/trash.vue").then(m => m.default || m)
  }
]