<template>
  <NuxtPwaManifest />
  <div>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
useHead({
  htmlAttrs: {
    class: "dark h-full",
  },
  bodyAttrs: {
    class: "h-full overflow-y-scroll",
  },
});

// Fixes issue with Safari and localhost Supabase login
// https://github.com/nuxt-modules/supabase/issues/300

const supabase = useSupabaseClient();

// get cookie options
const config = useRuntimeConfig().public.supabase;
const { cookieOptions } = config;
type SameSiteOption = boolean | "lax" | "strict" | "none" | undefined;

const accessToken = useCookie("sb-access-token", {
  maxAge: cookieOptions.maxAge,
  path: "/",
  sameSite: cookieOptions.sameSite as SameSiteOption,
  // secure: cookieOptions.secure // if this is enabled it doesn't work anymore
});

const refreshToken = useCookie("sb-refresh-token", {
  maxAge: cookieOptions.maxAge,
  path: "/",
  sameSite: cookieOptions.sameSite as SameSiteOption,
});

// listen for auth changes, set/delete cookies
supabase.auth.onAuthStateChange((event, session) => {
  if (event === "SIGNED_OUT") {
    // delete cookies on sign out
    accessToken.value = null;
    refreshToken.value = null;
  } else if (event === "SIGNED_IN" || event === "TOKEN_REFRESHED") {
    // set cookies on sign in
    accessToken.value = session?.access_token;
    refreshToken.value = session?.refresh_token;
  }
});
</script>
