import emitter from "~/utils/mitt";

export default defineNuxtPlugin((_nuxtApp) => {
  if (process.client) {
    window.addEventListener("keydown", (event) => {
      if ((event.metaKey || event.ctrlKey) && event.key === "k") {
        emitter.emit("show-global-search", true);
        event.preventDefault();
      }
    });
  }
});
