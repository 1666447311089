import type { DirectiveBinding } from "vue";

type ExtendedHTMLElement = HTMLElement & {
  _clickOutside?: (e: MouseEvent) => void;
};
export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive("click-outside", {
    mounted(el: ExtendedHTMLElement, binding: DirectiveBinding) {
      const { value } = binding;
      const handler = (e: MouseEvent) => {
        if (!el.contains(e.target as Node)) {
          value();
        }
      };
      // HeadlessUI dialog doesn't propagate click events
      // Workaround: use mousedown event
      // https://github.com/tailwindlabs/headlessui/issues/834
      document.addEventListener("mousedown", handler);
      el._clickOutside = handler;
    },
    unmounted(el: ExtendedHTMLElement) {
      document.removeEventListener(
        "mousedown",
        el._clickOutside as EventListenerOrEventListenerObject,
      );
      delete el._clickOutside;
    },
  });
});
